/* eslint-disable react/self-closing-comp */
/* eslint-disable prefer-const */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { useParams } from "react-router-dom";
import { ChevronDown } from "react-feather";
import Avatar from "../../../shared/Avatar/Avatar";
import {
  PrimaryButton,
  TACPrimaryButton,
} from "../../../shared/Buttons/Buttons";
import { Card } from "../../../shared/Card/Card";
import {
  entollToCourse,
  getChapterDetail,
  getCoureChapter,
  getCourse,
} from "../../../store/features/UserDashboard/ShowCourse/ShowCourseApi";
import {
  enrollToThinkificCourse,
  loginToThinkificAndRedirect,
} from "../../../store/features/Thinkific/ThikificApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import userImage from "../../../assets/icons/user.png";

import "./viewfullccourse.scss";

const ViewFullCourse = () => {
  const { courseId, instructorId } = useParams();
  const [active, setActive] = useState(0);
  const [loadingCourseid, setLoadingCourseid] = useState(0);
  const dispatch = useAppDispatch();
  const thinkificState: any = useAppSelector((store: RootStore) => {
    return store.ThinkificSlice;
  });
  const coursesState: any = useAppSelector((store: RootStore) => {
    return store.ShowCourseSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  useEffect(() => {
    dispatch(getCourse({ courseId: Number(courseId) }));
    dispatch(getCoureChapter({ courseId: Number(courseId) }));
  }, []);

  useEffect(() => {
    if (thinkificState.login_req_success) {
      setLoadingCourseid(0);
    }
  }, [thinkificState.login_req_success]);
  const startLearning = (isInmobile: boolean) => {
    setLoadingCourseid(coursesState.course_detail.course_id);
    dispatch(
      enrollToThinkificCourse({
        courseId: coursesState.course_detail.course_id,
      })
    ).then((res) => {
      dispatch(
        loginToThinkificAndRedirect({
          slug: coursesState.course_detail.slug,
        })
      ).then((res: any) => {
        if (res.payload) {
          if (isInmobile) {
            window.location.href = res.payload.url;
          } else {
            window.open(res.payload.url, "_blank");
          }
        }
      });
    });
  };

  useEffect(() => {
    if (coursesState.enroll_success) {
      window.location.href = `${themeState.configs.site_url}/login/${authState.tac_user.email}/${coursesState.course_detail.slug}`;
    }
  }, [coursesState?.enroll_success]);
  const handleChapterDetial = (chapterId: number) => {
    if (chapterId === active) {
      setActive(0);
    } else {
      setActive(chapterId);
      dispatch(getChapterDetail({ chapterId }));
    }
  };
  return (
    <div className="view__course__seciton mt-5">
      <div className="courses__according">
        <div className="according__card">
          <span className="card_title">Lessons in this course</span>
          <div className="custom__according__section">
            {" "}
            <Accordion allowZeroExpanded>
              {coursesState.chapters_success &&
                coursesState?.chapters?.map((chapter: any) => {
                  return (
                    <AccordionItem key={chapter.id}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="accordian__content__wrapper">
                            <span
                              className={`test ${
                                active === chapter.id ? "active" : ""
                              }`}
                              onClick={() => handleChapterDetial(chapter.id)}
                            >
                              {chapter.name}
                            </span>
                            <ChevronDown />
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {coursesState.chapters_details_loading && (
                          <div className="align_center">
                            {" "}
                            <div className="spinner" />
                          </div>
                        )}
                        {coursesState?.chapters_detail_success &&
                          coursesState?.chapters_details.map((chapter: any) => {
                            return (
                              <span className="course__unit" key={chapter.id}>
                                {chapter.name}
                              </span>
                            );
                          })}
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
            </Accordion>
          </div>
        </div>
      </div>

      <div className="courses__detail">
        {coursesState.course_detail_success && (
          <Card>
            {coursesState.course_detail.payment_url &&
            coursesState.course_detail.course_id === Number(2456311) &&
            !authState.tac_user?.enrolled_certified_courses?.includes(
              coursesState.course_detail.course_id
            ) ? (
              <>
                <TACPrimaryButton
                  type="button"
                  text="Purchase Now"
                  width="tac__color__btn__color__handle__InLg"
                  onClick={() => {
                    window.open(
                      coursesState.course_detail.payment_url,
                      "_blank"
                    );
                  }}
                />
                <TACPrimaryButton
                  type="button"
                  text="Purchase Now"
                  width="tac__color__btn__color__handle__Insm"
                  onClick={() => {
                    window.location.href =
                      coursesState.course_detail.payment_url;
                  }}
                />
              </>
            ) : (
              <>
                {/* showing buttons for free  courses starts here */}
                {!coursesState.course_detail.is_premium_course &&
                  authState.tac_user?.plan === "free" &&
                  !coursesState?.course_detail?.individual_course_purchased &&
                  coursesState.course_detail.percentage_completed === 0 && (
                    <>
                      <TACPrimaryButton
                        type="button"
                        text="Free Preview"
                        width="tac__color__btn__color__handle__InLg"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              coursesState.course_detail.course_id)
                        }
                        onClick={() => startLearning(false)}
                      />
                      <TACPrimaryButton
                        type="button"
                        text="Free Preview"
                        width="tac__color__btn__color__handle__Insm"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              coursesState.course_detail.course_id)
                        }
                        onClick={() => startLearning(true)}
                      />
                    </>
                  )}

                {!coursesState.course_detail.is_premium_course &&
                  (authState.tac_user?.plan === "paid" ||
                    (coursesState.course_detail?.individual_course_price &&
                      coursesState?.course_detail
                        ?.individual_course_purchased)) &&
                  coursesState.course_detail.percentage_completed === 0 && (
                    <>
                      <TACPrimaryButton
                        type="button"
                        text="Start Course"
                        width="tac__color__btn__color__handle__InLg"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              coursesState.course_detail.course_id)
                        }
                        onClick={() => startLearning(false)}
                      />
                      <TACPrimaryButton
                        type="button"
                        text="Start Course"
                        width="tac__color__btn__color__handle__Insm"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              coursesState.course_detail.course_id)
                        }
                        onClick={() => startLearning(true)}
                      />
                    </>
                  )}
                {/* showing buttons for free courses end here */}

                {/* showing buttons for premium courses starts here */}
                {coursesState.course_detail.is_premium_course &&
                  authState.tac_user?.enrolled_certified_courses?.includes(
                    coursesState.course_detail.course_id
                  ) &&
                  coursesState.course_detail.percentage_completed === 0 && (
                    <>
                      <TACPrimaryButton
                        type="button"
                        text="Start Course"
                        width="tac__color__btn__color__handle__InLg"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              coursesState.course_detail.course_id)
                        }
                        onClick={() => startLearning(false)}
                      />
                      <TACPrimaryButton
                        type="button"
                        text="Start Course"
                        width="tac__color__btn__color__handle__Insm"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              coursesState.course_detail.course_id)
                        }
                        onClick={() => startLearning(true)}
                      />
                    </>
                  )}
                {coursesState.course_detail.is_premium_course &&
                  !authState.tac_user?.enrolled_certified_courses?.includes(
                    coursesState.course_detail.course_id
                  ) &&
                  coursesState.course_detail.percentage_completed === 0 && (
                    <>
                      <TACPrimaryButton
                        type="button"
                        text="Free Preview"
                        width="tac__color__btn__color__handle__InLg"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              coursesState.course_detail.course_id)
                        }
                        onClick={() => startLearning(false)}
                      />
                      <TACPrimaryButton
                        type="button"
                        text="Free Preview"
                        width="tac__color__btn__color__handle__Insm"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              coursesState.course_detail.course_id)
                        }
                        onClick={() => startLearning(true)}
                      />
                    </>
                  )}
                {/* showing buttons for premium courses ends here */}
                {coursesState.course_detail.percentage_completed > 0 &&
                  coursesState.course_detail.percentage_completed < 100 && (
                    <>
                      <TACPrimaryButton
                        type="button"
                        text="Resume Course"
                        width="tac__color__btn__color__handle__InLg"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              coursesState.course_detail.course_id)
                        }
                        onClick={() => startLearning(false)}
                      />
                      <TACPrimaryButton
                        type="button"
                        text="Resume Course"
                        width="tac__color__btn__color__handle__Insm"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              coursesState.course_detail.course_id)
                        }
                        onClick={() => startLearning(true)}
                      />
                    </>
                  )}

                {Math.floor(coursesState.course_detail.percentage_completed) ===
                  100 && (
                  <>
                    <TACPrimaryButton
                      type="button"
                      text="Course Completed"
                      width="tac__color__btn__color__handle__InLg"
                      loading={
                        coursesState.enroll_loading ||
                        (thinkificState.loading &&
                          loadingCourseid ===
                            coursesState.course_detail.course_id)
                      }
                      onClick={() => startLearning(false)}
                    />
                    <TACPrimaryButton
                      type="button"
                      text="Course Completed"
                      width="tac__color__btn__color__handle__Insm"
                      loading={
                        coursesState.enroll_loading ||
                        (thinkificState.loading &&
                          loadingCourseid ===
                            coursesState.course_detail.course_id)
                      }
                      onClick={() => startLearning(true)}
                    />
                  </>
                )}
              </>
            )}
            <span className="course__titile mt-1">
              {coursesState.course_detail.name}
            </span>
            <span className="course__duration mt-1">
              Duration{" "}
              {coursesState.course_detail.custom_duration
                ? coursesState.course_detail.custom_duration
                : "-"}{" "}
            </span>
            <span className="course__description mt-1">
              {coursesState.course_detail.description}
            </span>

            <div className="divider" />

            {coursesState.course_detail.instructors.map((instructor: any) => {
              return (
                <div className="instructor__profile" key={instructor.id}>
                  <div className="pic pic-handle">
                    <Avatar
                      imageUrl={
                        instructor.avatar_url?.startsWith("https", 0)
                          ? instructor?.avatar_url
                          : userImage
                      }
                    />
                  </div>
                  <div className="info">
                    <span className="name">
                      {instructor.first_name} {instructor.last_name}
                    </span>
                    <span className="occupation">{instructor.title}</span>
                  </div>
                </div>
              );
            })}
          </Card>
        )}
      </div>
    </div>
  );
};

export default ViewFullCourse;
